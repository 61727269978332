var stream = require('stream')

module.exports = function(url, opts) {
  if (!opts) opts = {}

  var es = new EventSource(url)
  var rs = new stream.Readable({objectMode:true})

  var json = !!opts.json
  var decode = function (data) {
    try {
      if (json) return JSON.parse(data)
      return data
    } catch (err) {
      return undefined
    }
  }

  rs._read = function() {}

  es.onmessage = function(e) {
    rs.push(decode(e.data))
  }

  es.onerror = function(err) {
    if (rs.listeners('error').length) rs.emit('error', err)
  }

  es.onopen = function () {
    rs.emit('open')
  }

  var destroyed = false
  rs.destroy = function() {
    if (destroyed) return
    destroyed = true
    es.close()
    rs.emit('close')
  }

  return rs
}